
/deep/ p {
    margin: 0;
}
.page-title {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333;
    margin: 20px 0;
    line-height: 1;
}
.goods-detail {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    .cover {
        width: 60px;
        height: 60px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: #fafafa;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .goods-title {
        width: 1%;
        flex: 1;
        margin-left: 20px;
    }
}
.goods-overview {
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    .title {
        font-size: 16px;
        line-height: 1;
    }
    .overview-content {
        margin-top: 20px;
        border: 1px solid #E5E5E5;
        display: flex;
        .oo-item {
            width: 50%;
            display: flex;
            align-items: center;
            .oo-title {
                width: 112px;
                height: 112px;
                background: #F8F8F8;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 42px;
                    margin-bottom: 2px;
                }
            }
            .oo-right {
                width: 1%;
                flex: 1;
                display: flex;
                .item {
                    width: 33.333333%;
                    padding-left: 20px;
                    p {
                        line-height: 1;
                        white-space: nowrap;
                    }
                    .text {
                        font-size: 16px;
                    }
                    .num {
                        font-size: 18px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
.trend-wrapper {
    background: #fff;
    padding: 20px;
    .trend-category {
        display: flex;
        border: 1px solid #E5E5E5;
        .cate-left {
            width: 120px;
            background: #F8F8F8;
            p {
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                border-bottom: 1px solid #E5E5E5;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
        .cate-right {
            width: 1%;
            flex: 1;
            .el-checkbox-group {
                display: flex;
                flex-wrap: wrap;
                .el-checkbox {
                    margin-right: 0;
                    line-height: 50px;
                    padding-left: 48px;
                    width: 15%;
                    box-sizing: border-box;
                    &:nth-of-type(2) {
                        width: 85%;
                    }
                    &:nth-of-type(1), &:nth-of-type(2) {
                        border-bottom: 1px solid #E5E5E5;
                    }
                }
            }
        }
    }
    .choose-number {
        margin-top: 30px;
        font-size: 18px;
        .number {
            margin-left: 20px;
            span {
                color: #0084FF;
            }
        }
    }
    .trend-content {
        margin-top: 30px;
        height: 300px;
    }
}
.goods-attr {
    margin-top: 20px;
    background: #fff;
    .attr-title {
        line-height: 55px;
        padding: 0 20px;
        font-size: 16px;
        border-bottom: 1px solid #E5E5E5;
    }
    .attr-list {
        padding: 20px;
        .item {
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
